import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Box } from '@walltowall/calico'

import { TagTemplateQuery } from '../graphqlTypes'

import { BoundedBox } from '../components/BoundedBox'
import { Layout } from '../components/Layout'
import { PostCard } from '../components/PostCard'
import { PostCardList } from '../components/PostCardList'
import { PageIntro } from '../components/PageIntro'
import { TagNavigation } from '../components/TagNavigation'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

export type TagTemplateProps = PageProps<TagTemplateQuery>

export const TagTemplate = ({ data, location }: TagTemplateProps) => {
  const siteMetadata = useSiteMetadata()
  const posts = data.allFile.nodes.map((node) => node.childMarkdownRemark)
  const tags = data.tags.group
    .sort((a, b) => b.totalCount - a.totalCount)
    .map((tag) => tag.fieldValue)

  return (
    <Layout>
      <BoundedBox styles={{ paddingBottom: [20, 25, 30] }}>
        <Box styles={{ marginBottom: [12, 15, 18] }}>
          <PageIntro
            variant="titleCaps"
            title={siteMetadata.titleShort}
            excerpt={siteMetadata.description}
          />
        </Box>
        <Box styles={{ marginBottom: [6, 8, 10] }}>
          <TagNavigation location={location}>
            {tags.map((tag) => (
              <TagNavigation.Tag key={tag}>{tag}</TagNavigation.Tag>
            ))}
          </TagNavigation>
        </Box>
        <Box
          styles={{
            width: 'full',
            maxWidth: 'xlarge',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <PostCardList>
            {posts.map(
              (post) =>
                post && (
                  <PostCard
                    key={post.fields?.url}
                    href={post?.fields?.url!}
                    authorName={post?.frontmatter?.author?.shortName}
                    authorHref={post?.frontmatter?.author?.fields?.url}
                    authorColor={post.frontmatter?.authorColor}
                    date={post?.frontmatter?.date}
                    title={post?.frontmatter?.title!}
                    excerpt={post?.frontmatter?.excerpt}
                    tags={post?.fields?.tags}
                  />
                ),
            )}
          </PostCardList>
        </Box>
      </BoundedBox>
    </Layout>
  )
}

export default TagTemplate

export const query = graphql`
  query TagTemplate($tag: String!) {
    allFile(
      filter: {
        sourceInstanceName: { eq: "posts" }
        base: { eq: "index.md" }
        childMarkdownRemark: { frontmatter: { tags: { in: [$tag] } } }
      }
      sort: { fields: [childMarkdownRemark___frontmatter___date], order: DESC }
    ) {
      nodes {
        childMarkdownRemark {
          ...Post
        }
      }
    }
    ...Tags
  }
`

import * as React from 'react'
import { useStyles } from 'react-treat'
import { WindowLocation } from '@reach/router'
import { isPathActive, isPathActiveExact } from '@walltowall/helpers'
import slugify from 'slugify'

import { Inline } from './Inline'
import { Text } from './Text'
import { Link } from './Link'
import { Divider } from './Divider'

import * as styleRefs from './TagNavigation.treat'

type TagNavigation = {
  children?: React.ReactNode
  location: WindowLocation
}

export const TagNavigation = ({ children, location }: TagNavigation) => {
  return (
    <Inline space={[5, 6, 8]} spaceY={[3, 5, 6]} align="center">
      <TagNavigation.Tag href="/" location={location}>
        All
      </TagNavigation.Tag>
      {React.Children.map(
        children,
        (child) =>
          React.isValidElement(child) &&
          React.cloneElement(child, {
            location: child.props.location ?? location,
          }),
      )}
    </Inline>
  )
}

type TagNavigationTagProps = {
  children?: string
  href?: string
  location?: WindowLocation
}

const TagNavigationTag = ({
  children,
  href: rawHref,
  location,
}: TagNavigationTagProps) => {
  const styles = useStyles(styleRefs)

  const href =
    rawHref ??
    (children ? `/tags/${slugify(children, { lower: true })}/` : undefined)

  const isActive =
    href &&
    location &&
    (isPathActive(href, location) || isPathActiveExact(href, location))

  return (
    <Link href={href!} className={styles.focusCapture}>
      <Text
        variant="mono-caps-12-16"
        styles={{ marginBottom: [1.5, 2], color: 'gray10' }}
      >
        {children}
      </Text>
      <Divider
        color="yellow50"
        className={styles.dividerOpacity}
        styles={{
          height: '2px',
          opacity: isActive ? 100 : 0,
          transitionDuration: 'normal',
          transitionProperty: 'opacity',
          transitionTimingFunction: 'easeOut',
        }}
      />
    </Link>
  )
}

TagNavigation.Tag = TagNavigationTag
